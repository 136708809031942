import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import VideoEmbed from "../../components/video-embed/video-embed";
import { OnChatBotClient } from "@gameon/web/react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import "./test-page.scss";

export const query = graphql`
  query($id: String!) {
    contentfulTestPage(id: { eq: $id }) {
      id
      title
      slug
      theme
      node_locale
      heroTitle
      heroImage {
        gatsbyImageData(
          width: 3200
          height: 1940
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
        url
        width
        height
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      video {
        contentful_id
        videoTitle
        videoUrl
        videoCaption
      }
      createdAt
      updatedAt
      chatBotId
      chatBotEnvironment
    }
  }
`;

const IS_BROWSER = typeof window !== "undefined";

const TestPage = ({ data, pageContext }) => {
  const {
    theme,
    heroTitle,
    heroImage,
    content: {
      childMarkdownRemark: { html },
    },
    video,
    chatBotId,
    chatBotEnvironment,
  } = data.contentfulTestPage;

  return (
    <Layout theme={theme} type={pageContext.type}>
      {heroImage && (
        <div className="hero-chat-stationary-web">
          <h1 className="hero-chat-stationary__title">{heroTitle}</h1>
          <GatsbyImage
            image={heroImage.gatsbyImageData}
            alt={heroTitle}
            className="hero-chat-stationary__image"
          />
        </div>
      )}
      <section
        className="under-chat"
        dangerouslySetInnerHTML={{ __html: html }}
      ></section>

      {video && (
        <VideoEmbed videoLink={video.videoUrl} title={video.videoTitle} />
      )}

      {IS_BROWSER && chatBotId && (
        <OnChatBotClient
          clientId={chatBotId}
          env={chatBotEnvironment.toUpperCase()}
          displayMode="WIDGET"
        />
      )}
    </Layout>
  );
};

export const Head = ({
  data: {
    contentfulTestPage: { createdAt, updatedAt, title, heroImage },
  },
  pageContext: { slug, locale },
}) => {
  const seoMeta = {
    metaTitle: title,
    locale: locale,
    slug: slug,
    createdAt,
    updatedAt,
    image: { ...heroImage },
  };
  return <Seo {...seoMeta} />;
};

export default TestPage;
