import PropTypes from "prop-types";
import React from "react";

import "./video-embed.scss";

const VideoEmbed = ({ videoLink, title }) => {
  return (
    <div className="video-embed">
      <iframe
        src={videoLink}
        width="640"
        height="1385"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen={true}
        title={title}
      ></iframe>
    </div>
  );
};

VideoEmbed.propTypes = {
  videoLink: PropTypes.string.isRequired,
  title: PropTypes.string,
};

VideoEmbed.defaultProps = {
  title: "GameOn Demo",
};

export default VideoEmbed;
